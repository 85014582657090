
import data from '@/data/co.data'
import mixBannerBase from '@/pages/mixins/mix.banner.base'

export default {
  mixins: [mixBannerBase],
  data () {
    return {
    }
  },
  created () {
    this.navSelf.text = '应用场景'
    this.navSelf.href = '/scene'
    this.classify = {
      height: data.height.minor,
      points: data.points.minor,
    }
    this.banItems = data.banner.scene
  },
  methods: {
  }
}
