<template>
  <section>
    <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
    />

    <card-scenes
      :header="indHeader"
      :items="indItems"
      :button="button"
    />
    <guide-content
      :header="gdLab.header"
      :cover="gdLab.cover"
      :button="gdLab.button"
      @view="onViewLab"
    />
    <card-scenes
      :header="hosHeader"
      :items="hosItems"
      per-columns="2"
      :button="button"
    />
    <guide-content
      :header="gdHotel.header"
      :cover="gdHotel.cover"
      :button="gdHotel.button"
      @view="onViewHotel"
    />
    <section-foot />
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import data from '@/data/co.data'

  import mixSceneBanner from '@/pages/mixins/scene/mix.scene.banner'

  export default {
    components: {
      CardScenes: () => import('@/pages/sections/comm/CardScenes.vue'),
      GuideContent: () => import('@/pages/sections/comm/GuideContent.vue'),
      SectionFoot: () => import('@/pages/sections/Foot.vue'),
    },
    metaInfo: { title: '应用场景' },
    mixins: [
      mixSceneBanner
    ],
    data () {
      return {
        userInfo: {},
        button: {},
        indHeader: {
          title: '制造业及危化品应用场景',
        },
        indItems: [],
        hosHeader: {
          title: '卫生服务应用场景',
        },
        hosItems: [],
        gdLab: data.scene.guideLab,
        gdHotel: data.scene.guideHotel,
      }
    },
    created () {
      const indType = data.scene.Types.INDUSTRY + '|' + data.scene.Types.CHEMICAL
      const hosType = data.scene.Types.HOSPITAL
      this.indItems = data.scene.getItems(indType)
      this.hosItems = data.scene.getItems(hosType)
    },
    methods: {
      onViewLab (ev) {
        const params = {
          contentId: this.gdLab.contentId,
        }

        api.page.navigateBy(this, 'sceneDetail', params)
      },
      onViewHotel (ev) {
        const params = {
          contentId: this.gdHotel.contentId,
        }

        api.page.navigateBy(this, 'sceneDetail', params)
      }
    }
  }
</script>
